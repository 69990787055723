import React, {useState, Fragment} from 'react';
import 'normalize.css';
import './App.css';


function TodoList(props) {
  return (
    <ul>
      {props.todos.map(todo => (
        <TodoItem key={todo.id} todo={todo} toggleComplete={props.toggleComplete} deleteTodo={props.deleteTodo} />
      ))}
    </ul>
  )
}

function TodoItem(props) {
    return (
      <li>
        <input type="checkbox" id={props.todo.id} checked={props.todo.complete} onChange={props.toggleComplete} />
        <label htmlFor={props.todo.id} />
        {props.todo.title}
        <a href="#/" id={props.todo.id} onClick={props.deleteTodo} className="delete">&#xf068;</a>
      </li>
    );
}

// normally styles would take care of spacing,
// but for not styles we bust out the nbsp
function FilterControl(props) {
    return (
      <div className="filters">
        <a href="#/" className={props.filter === 'all' ? 'current' : ''} onClick={props.setFilter}>all</a>&nbsp;
        <a href="#/" className={props.filter === 'complete' ? 'current' : ''} onClick={props.setFilter}>complete</a>&nbsp;
        <a href="#/" className={props.filter === 'incomplete' ? 'current' : ''} onClick={props.setFilter}>incomplete</a>&nbsp;
      </div>
    );
}


function Todo(props) {
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState('');
  const [filter, setFilter] = useState('all');
  const [idx, setIdx] = useState(0);
  
  
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      setTodos([...todos, {id: idx, title: e.target.value, complete: false}]);
      setInput('');
      setIdx(idx + 1);
    }
  }

  function handleInputChange(e) {
    setInput(e.target.value);
  }

  function handleFilterClick(e) {
    setFilter(e.target.text);
  }

  function toggleComplete(e) {
    setTodos(todos.map(todo => todo.id === Number(e.target.id) ? {id: todo.id, title: todo.title, complete: !todo.complete} : todo));
  }

  function deleteTodo(e) {
    setTodos(todos.filter(todo => todo.id !== Number(e.target.id)));
  }

  function filterPredicate(todo) {
    switch(filter) {
      case 'complete':
        return todo.complete;
      case 'incomplete':
        return !todo.complete;
      default:
        return true;
    }
  }


  return (
    <Fragment>
    <h1>Todo</h1>
    <FilterControl setFilter={handleFilterClick} filter={filter} />
    <div className='drop-shadow'>
      <input value={input} placeholder="I need to..." onKeyDown={handleKeyDown} onChange={handleInputChange} type="text" />
      <TodoList todos={todos.filter(filterPredicate)} toggleComplete={toggleComplete} deleteTodo={deleteTodo} />
    </div>
  </Fragment>
  );
}

function App() {
  return (
    <div className="App">
      <Todo />
    </div>
  );
}

export default App;
